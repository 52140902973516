<template>
	<el-select
		:disabled="Boolean(disabled)"
		:popper-class="className"
		@visible-change="visibleStaff"
		style="width: 100%;"
		size="small"
		v-model="value"
		:multiple="multiple"
		:placeholder="placeholder"
	>
		<el-option v-for="item in list" :key="item.user_id" :label="item.name" :value="item.user_id"></el-option>
	</el-select>
</template>

<script>
import { reqGetSoloStaff } from '@/api/index';
export default {
	props: {
		multiple: {
			// 是否多选
			default: false,
			type: Boolean
		},
		disabled: {
			default: false
		},
		placeholder: {
			default: '',
			type: String
		},
		className: {
			type: String
		},
		showValue: {
			default: []
		}
	},
	data() {
		return {
			value: [],
			limit: 20,
			total: 0,
			list: [],
			loading: false
		};
	},
	watch: {
		value(val, form) {
			this.$emit('getStaff', val);
		},
		showValue(val) {
			this.value = val;
		}
	},
	created() {
		this.getStaffList();
	},
	methods: {
		getStaffList() {
			this.loading = true;
			reqGetSoloStaff({
				page: 1,
				limit: this.limit
			}).then(res => {
				this.list = res.data;
				this.total = res.count || 0;
				this.loading = false;
				if (this.list.length < this.total) {
					this.limit += 20;
					this.getStaffList();
				}
			});
		},
		visibleStaff(flag) {
			this.$nextTick(() => {
				const oScroll = document.querySelector(`.${this.className} .el-scrollbar__wrap`);
				if (flag)
					oScroll.addEventListener('mousewheel', () => {
						if (oScroll.scrollTop + oScroll.clientHeight + 100 >= oScroll.scrollHeight) {
							if (!this.loading && this.list.length < this.total) {
								this.limit += 15;
								this.getStaffList();
							}
						}
					});
			});
		}
	}
};
</script>
