<template>
	<div class="behavior">
		<div class="title">
			{{ $route.meta.title }}
			<i @click="showCourse = !showCourse" class="iconfont icon-question-circle"></i>
		</div>
		<div class="course" v-show="showCourse">
			<p><span class="black">1、系统设置4种敏感行为，设置好适用成员及提醒对象后，可收到违规提醒。</span></p>
		</div>
		<div class="flex">
			<el-input size="small" v-model="keyword" style="width: 200px;margin-right: 10px;" placeholder="请输入违规行为"></el-input>
			<el-button size="small" class="btn-blue" @click="handleSearch">搜索</el-button>
		</div>
		<div class="table">
			<el-table
				v-loading="loading"
				:data="tableData"
				style="width: 100%"
				:cell-style="{ textAlign: 'center' }"
				:header-cell-style="{
					color: '#333',
					fontSize: '14px',
					backgroundColor: '#F6F7FB',
					fontWeight: 'normal',
					textAlign: 'center'
				}"
			>
				<el-table-column prop="name" label="违规行为"></el-table-column>
				<el-table-column label="适用范围">
					<el-table-column prop="entry_num" label="成员">
						<template v-slot="scope">
							{{ scope.row.staff_user_id.join(',') || '--' }}
						</template>
					</el-table-column>
					<el-table-column prop="praise" label="群聊">
						<template v-slot="scope">
							{{ scope.row.chat_group_id.join(',') || '--' }}
						</template>
					</el-table-column>
				</el-table-column>
				<el-table-column prop="name" label="通知成员">
					<template v-slot="scope">
						{{ scope.row.notice_staff.join(',') || '--' }}
					</template>
				</el-table-column>
				<el-table-column label="操作">
					<template slot-scope="scope">
						<el-button type="text" @click="handleEdit(scope.row)">编辑</el-button>
					</template>
				</el-table-column>
			</el-table>
		</div>
		<div class="pagination" v-if="total !== 0">
			<el-pagination
				@size-change="
					pageSize => {
						limit = pageSize;
						page = 1;
						getList();
					}
				"
				@current-change="
					currentPage => {
						page = currentPage;
						getList();
					}
				"
				:current-page="page"
				:page-sizes="[10, 20, 40, 80]"
				:page-size="limit"
				layout="total, sizes, prev, pager, next, jumper"
				:total="total"
			></el-pagination>
		</div>
		<!-- 编辑行为 -->
		<el-dialog
			title="编辑行为"
			:visible.sync="visible"
			:close-on-click-modal="false"
			width="500px"
			:before-close="
				() => {
					visible = false;
				}
			"
		>
			<el-form label-position="left" label-width="120px" v-loading="form.loading" :model="form" ref="form">
				<el-form-item label="适用成员">
					<select-staff :showValue="form.showStaff" v-show="!form.isStaffAll" className="staff" placeholder="请选择成员" :multiple="true" @getStaff="getStaff" />
					<el-checkbox :true-label="1" :false-label="0" v-model="form.isStaffAll">全部成员</el-checkbox>
				</el-form-item>
				<el-form-item label="适用群聊">
					<select-group :showValue="form.showGroup" v-show="!form.isGroupAll" className="group" placeholder="请选择群聊" :multiple="true" @getGroup="getGroup" />
					<el-checkbox :true-label="1" :false-label="0" v-model="form.isGroupAll">全部群聊</el-checkbox>
				</el-form-item>
				<el-form-item label="违规通知成员">
					<select-staff :showValue="form.showNotiveStaff" className="notice-staff" placeholder="请选择违规通知成员" :multiple="true" @getStaff="getNoticeStaff" />
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button size="small" class="btn-white" @click="visible = false">取 消</el-button>
				<el-button size="small" class="btn-blue" @click="handleSubmit">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
import { reqGetViolationList, reqShowViolation, reqEditViolation } from '@/api/index';
import SelectStaff from '@/components/SelectStaff.vue';
import SelectGroup from '@/components/SelectGroup.vue';
export default {
	components: {
		SelectStaff,
		SelectGroup
	},
	data() {
		return {
			showCourse: false,
			keyword: '',
			page: 1,
			limit: 10,
			total: 0,
			loading: false,
			tableData: [],
			form: {
				staff: [],
				notice_staff: [],
				group: [],
				isStaffAll: 0,
				isGroupAll: 0,
				showStaff: [],
				showGroup: [],
				showNotiveStaff: [],
				loading: false,
				id: ''
			},
			visible: false,
			staffList: []
		};
	},
	watch: {
		keyword(val) {
			if (!val) {
				this.page = 1;
				this.getList();
			}
		},
		visible(val) {
			if (!val) {
				this.$refs.form.resetFields();
				Object.assign(this.$data.form, this.$options.data().form);
			}
		}
	},
	created() {
		this.getList();
	},
	methods: {
		getList() {
			this.loading = true;
			reqGetViolationList({
				page: this.page,
				limit: this.limit,
				keyword: this.keyword
			}).then(res => {
				this.tableData = res.data;
				this.total = res.count || 0;
				this.loading = false;
			});
		},
		showViolation(id) {
			this.form.loading = true;
			reqShowViolation({ id }).then(res => {
				this.form.showNotiveStaff = res.data.notice_staff.length ? res.data.notice_staff.split(',') : [];
				if (res.data.staff_user_id === '1') {
					this.form.isStaffAll = 1;
				} else if (res.data.staff_user_id) {
					this.form.showStaff = res.data.staff_user_id.split(',');
				}
				if (res.data.chat_group_id === '1') {
					this.form.isGroupAll = 1;
				} else if (res.data.chat_group_id) {
					this.form.showGroup = res.data.chat_group_id.split(',');
				}
				this.form.id = res.data.id;
				this.form.loading = false;
			});
		},
		// 编辑违规行为
		editViolation(params) {
			reqEditViolation(params).then(res => {
				this.getList();
				this.$message.success(res.msg);
				this.visible = false;
			});
		},
		handleSearch() {
			if (this.keyword) {
				this.page = 1;
				this.getList();
			}
		},
		getStaff(val) {
			this.form.staff = val;
		},
		getNoticeStaff(val) {
			this.form.notice_staff = val;
		},
		getGroup(val) {
			this.form.group = val;
		},
		handleEdit(row) {
			this.visible = true;
			this.showViolation(row.id);
		},
		handleSubmit() {
			this.$refs['form'].validate(valid => {
				if (valid) {
					if (true || this.form.isGroupAll == 1 || this.form.isStaffAll == 1 || this.form.staff.length || this.form.group.length) {
						const params = {};
						params.staff_user_id = this.form.isStaffAll == 1 ? 1 : this.form.staff.join(',');
						params.chat_group_id = this.form.isGroupAll == 1 ? 1 : this.form.group.join(',');
						params.notice_staff = this.form.notice_staff.join(',');
						this.form.id ? (params.id = this.form.id) : '';
						this.editViolation(params);
					} else {
						this.$message.error('至少选择一项适用目标');
						return false;
					}
				} else {
					return false;
				}
			});
		}
	}
};
</script>

<style lang="less" scoped>
.behavior {
	width: 100%;
	position: relative;
	overflow: hidden;

	.flex {
		display: flex;
	}

	> div + div {
		margin-top: 15px;
	}
}
</style>
