var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"behavior"},[_c('div',{staticClass:"title"},[_vm._v(" "+_vm._s(_vm.$route.meta.title)+" "),_c('i',{staticClass:"iconfont icon-question-circle",on:{"click":function($event){_vm.showCourse = !_vm.showCourse}}})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.showCourse),expression:"showCourse"}],staticClass:"course"},[_vm._m(0)]),_c('div',{staticClass:"flex"},[_c('el-input',{staticStyle:{"width":"200px","margin-right":"10px"},attrs:{"size":"small","placeholder":"请输入违规行为"},model:{value:(_vm.keyword),callback:function ($$v) {_vm.keyword=$$v},expression:"keyword"}}),_c('el-button',{staticClass:"btn-blue",attrs:{"size":"small"},on:{"click":_vm.handleSearch}},[_vm._v("搜索")])],1),_c('div',{staticClass:"table"},[_c('el-table',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.loading),expression:"loading"}],staticStyle:{"width":"100%"},attrs:{"data":_vm.tableData,"cell-style":{ textAlign: 'center' },"header-cell-style":{
				color: '#333',
				fontSize: '14px',
				backgroundColor: '#F6F7FB',
				fontWeight: 'normal',
				textAlign: 'center'
			}}},[_c('el-table-column',{attrs:{"prop":"name","label":"违规行为"}}),_c('el-table-column',{attrs:{"label":"适用范围"}},[_c('el-table-column',{attrs:{"prop":"entry_num","label":"成员"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.staff_user_id.join(',') || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"prop":"praise","label":"群聊"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.chat_group_id.join(',') || '--')+" ")]}}])})],1),_c('el-table-column',{attrs:{"prop":"name","label":"通知成员"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_vm._v(" "+_vm._s(scope.row.notice_staff.join(',') || '--')+" ")]}}])}),_c('el-table-column',{attrs:{"label":"操作"},scopedSlots:_vm._u([{key:"default",fn:function(scope){return [_c('el-button',{attrs:{"type":"text"},on:{"click":function($event){return _vm.handleEdit(scope.row)}}},[_vm._v("编辑")])]}}])})],1)],1),(_vm.total !== 0)?_c('div',{staticClass:"pagination"},[_c('el-pagination',{attrs:{"current-page":_vm.page,"page-sizes":[10, 20, 40, 80],"page-size":_vm.limit,"layout":"total, sizes, prev, pager, next, jumper","total":_vm.total},on:{"size-change":function (pageSize) {
					_vm.limit = pageSize;
					_vm.page = 1;
					_vm.getList();
				},"current-change":function (currentPage) {
					_vm.page = currentPage;
					_vm.getList();
				}}})],1):_vm._e(),_c('el-dialog',{attrs:{"title":"编辑行为","visible":_vm.visible,"close-on-click-modal":false,"width":"500px","before-close":function () {
				_vm.visible = false;
			}},on:{"update:visible":function($event){_vm.visible=$event}}},[_c('el-form',{directives:[{name:"loading",rawName:"v-loading",value:(_vm.form.loading),expression:"form.loading"}],ref:"form",attrs:{"label-position":"left","label-width":"120px","model":_vm.form}},[_c('el-form-item',{attrs:{"label":"适用成员"}},[_c('select-staff',{directives:[{name:"show",rawName:"v-show",value:(!_vm.form.isStaffAll),expression:"!form.isStaffAll"}],attrs:{"showValue":_vm.form.showStaff,"className":"staff","placeholder":"请选择成员","multiple":true},on:{"getStaff":_vm.getStaff}}),_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},model:{value:(_vm.form.isStaffAll),callback:function ($$v) {_vm.$set(_vm.form, "isStaffAll", $$v)},expression:"form.isStaffAll"}},[_vm._v("全部成员")])],1),_c('el-form-item',{attrs:{"label":"适用群聊"}},[_c('select-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.form.isGroupAll),expression:"!form.isGroupAll"}],attrs:{"showValue":_vm.form.showGroup,"className":"group","placeholder":"请选择群聊","multiple":true},on:{"getGroup":_vm.getGroup}}),_c('el-checkbox',{attrs:{"true-label":1,"false-label":0},model:{value:(_vm.form.isGroupAll),callback:function ($$v) {_vm.$set(_vm.form, "isGroupAll", $$v)},expression:"form.isGroupAll"}},[_vm._v("全部群聊")])],1),_c('el-form-item',{attrs:{"label":"违规通知成员"}},[_c('select-staff',{attrs:{"showValue":_vm.form.showNotiveStaff,"className":"notice-staff","placeholder":"请选择违规通知成员","multiple":true},on:{"getStaff":_vm.getNoticeStaff}})],1)],1),_c('span',{staticClass:"dialog-footer",attrs:{"slot":"footer"},slot:"footer"},[_c('el-button',{staticClass:"btn-white",attrs:{"size":"small"},on:{"click":function($event){_vm.visible = false}}},[_vm._v("取 消")]),_c('el-button',{staticClass:"btn-blue",attrs:{"size":"small"},on:{"click":_vm.handleSubmit}},[_vm._v("确 定")])],1)],1)],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('p',[_c('span',{staticClass:"black"},[_vm._v("1、系统设置4种敏感行为，设置好适用成员及提醒对象后，可收到违规提醒。")])])}]

export { render, staticRenderFns }